import { Checkbox, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { RootState } from '../../../stores/Store';
import { Header, Row } from '../../../components/Table/TableModels';
import { Content, BulkImportValidRowsRequest, UploadType } from '../../../models/ImportModels';
import { NotificationSetting } from '../../../models/CompanyOnboardingModels';
import ImportService from '../../../services/Import/ImportService';
import CompanyOnboardingService from '../../../services/CompanyOnboarding/CompanyOnboardingService';
import {
  setBulkImportValidRowsResponse,
  setExcludedImports,
  setNotificationSettings,
  setCompanyId,
} from '../../../stores/ImportStore';

const sourceHeaders = [
  'sourceEndUserFirstName',
  'sourceEndUserLastName',
  'sourceEndUserEmail',
  'sourceDeviceManufacturer',
  'sourceDeviceModel',
  'sourceDeviceOs',
  'sourceDeviceSerialNumber',
  'sourcePhoneNumber',
  'sourceEid',
];
const targetHeaders = [
  'endUserFirstName',
  'endUserLastName',
  'endUserEmail',
  'deviceManufacturer',
  'deviceModel',
  'deviceOs',
  'deviceSerialNumber',
  'eid',
];

const simSwapHeaders = ['checkbox', ...sourceHeaders, ...targetHeaders];
const newConnectionsHeaders = ['checkbox', ...targetHeaders];

function useBulkImportSelectRows(activePage: number, pageSize: number) {
  const [t] = useTranslation();
  const theme = useTheme();
  const snackBar = useSnackBar();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [excludedLines, setExcludedLines] = useState(0);
  const [tableHeaders, setTableHeaders] = useState(simSwapHeaders);
  const [bulkImportRows, setBulkImportRows] = useState<Content[]>([]);
  const { importResponse, excludedImports, qrCodeNotificationSettings } = useSelector(
    (state: RootState) => state.importReducer,
  );

  /**
   * Retrieves only the types of notification that are enabled
   * @param notifications
   */
  const filterEnabledQrCodeNotifications = (notifications: NotificationSetting[]): NotificationSetting[] =>
    notifications.filter((notification) => notification.enabled);

  /**
   * Fetches the list of notification settings of the company
   * @param companyId
   */
  const getNotificationSettings = (companyId: string) => {
    setIsLoading(true);
    CompanyOnboardingService.api
      .fetchCompanyNotificationSettings(companyId)
      .then(
        (res) => {
          dispatch(setNotificationSettings(filterEnabledQrCodeNotifications(res.data.qrCodeNotifications)));
        },
        () => {
          snackBar.showSnackBar(
            t('pages.eSimManager.newConnectionDrawer.fetchCompanyNotificationsDefaultError'),
            'error',
          );
        },
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * Fetch bulk import rows
   * @param uploadType
   * @param uploadId
   */
  const fetchBulkImportValidRows = (uploadType: UploadType, uploadId: string) => {
    setIsLoading(true);
    const requestParams: BulkImportValidRowsRequest = {
      uploadId,
      uploadType,
      page: activePage - 1,
      pageSize,
      sort: 'creationDate',
      sortingType: 'desc',
    };
    ImportService.api.fetchBulkImportValidRows(requestParams).then(
      (response) => {
        dispatch(setBulkImportValidRowsResponse(response.data));
        setBulkImportRows(response.data.content);
        if (qrCodeNotificationSettings.length === 0) {
          dispatch(setCompanyId(response.data.companyId));
          getNotificationSettings(response.data.companyId);
        }
        setTotalItems(response.data.totalElements);
        setExcludedLines(response.data.excludedLines);
        setIsLoading(false);
      },
      () => {
        snackBar.showSnackBar(t('pages.bulkImport.validRowsError'), 'error');
        setIsLoading(false);
      },
    );
  };

  React.useEffect(() => {
    if (importResponse) {
      fetchBulkImportValidRows(importResponse.uploadType, importResponse.uploadId);
      switch (importResponse.uploadType) {
        case UploadType.SIM_SWAPS:
          setTableHeaders(simSwapHeaders);
          break;
        case UploadType.NEW_CONNECTIONS:
          setTableHeaders(newConnectionsHeaders);
          break;
        default:
          break;
      }
    }
  }, [activePage, pageSize]);

  /**
   * Selects and unselects all rows currently shown
   * @param event
   */
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newExcludedImports: number[] = [...excludedImports];
    const currentRowsIds = bulkImportRows.map((row) => row.numberOfLine);

    // remove or add current rows ids to the array
    if (event.target.checked) {
      newExcludedImports = newExcludedImports.filter((id) => !currentRowsIds.includes(id));
    } else {
      newExcludedImports.push(...currentRowsIds);
    }
    dispatch(setExcludedImports(newExcludedImports));
  };

  /**
   * Sets a new value or removes it from the array
   * @param id
   * @param checked
   */
  const handleClick = (id: number, checked: boolean) => {
    const newExcludedImports: number[] = [...excludedImports];
    dispatch(
      setExcludedImports(
        checked ? newExcludedImports.filter((element) => element !== id) : [...newExcludedImports, id],
      ),
    );
  };

  /**
   * Retrive header checkbox current state
   * @param item
   */
  const getCheckboxState = (): 'checked' | 'indeterminate' | 'unchecked' => {
    const currentRowsIds = bulkImportRows.map((row) => row.numberOfLine);
    const currentRowsUnselected = excludedImports.filter((id) => currentRowsIds.includes(id));

    if (currentRowsUnselected.length === currentRowsIds.length) {
      return 'unchecked';
    }
    if (currentRowsUnselected.length > 0) {
      return 'indeterminate';
    }
    return 'checked';
  };

  /**
   * Retrieve the Header object of each item
   * @param item
   */
  const getHeader = (item: string): Header => {
    switch (item) {
      case 'checkbox':
        return {
          id: 'checkbox',
          label: (
            <Checkbox
              color="primaryCheckbox"
              onChange={handleSelectAllClick}
              indeterminate={getCheckboxState() === 'indeterminate'}
              checked={getCheckboxState() === 'checked'}
              sx={{ marginLeft: '-0.5rem' }}
            />
          ),
          sx: {
            borderRight: `1px solid ${theme.palette.monochrome.monoChrome10}`,
            padding: '0 0.5rem',
            minWidth: '4rem',
            textAlign: 'center',
          },
        };
      default:
        return {
          id: item,
          label: t(`pages.bulkImport.table.selectTable.headers.${item}`),
          sx: { minWidth: '12rem' },
        };
    }
  };

  const headers: Header[] = useMemo(
    () => tableHeaders.map((item) => getHeader(item)),
    [t, bulkImportRows, excludedImports, tableHeaders],
  );

  const rows: Row[] = useMemo(
    () =>
      bulkImportRows.map(({ source, target, numberOfLine }) => {
        const sourceCells =
          importResponse?.uploadType === UploadType.SIM_SWAPS && source
            ? [
                {
                  value: source.userFirstName,
                },
                {
                  value: source.userLastName,
                },
                {
                  value: source.userEmail,
                },
                {
                  value: source.manufacturer,
                },
                {
                  value: source.model,
                },
                {
                  value: source.os,
                },
                {
                  value: source.serialNumber,
                },
                {
                  value: source.phoneNumber || '',
                },
                {
                  value: source.eid,
                },
              ]
            : [];
        const targetCells = target
          ? [
              {
                value: target.userFirstName,
              },
              {
                value: target.userLastName,
              },
              {
                value: target.userEmail,
              },
              {
                value: target.manufacturer,
              },
              {
                value: target.model,
              },
              {
                value: target.os,
              },
              {
                value: target.serialNumber,
              },
              {
                value: target.eid,
              },
            ]
          : [];
        const isItemSelected = !excludedImports.includes(numberOfLine);
        return {
          id: `row-${numberOfLine}`,
          cells: [
            {
              value: (
                <Checkbox
                  color="primaryCheckbox"
                  onChange={(_e, checked) => handleClick(numberOfLine, checked)}
                  checked={isItemSelected}
                />
              ),
              isCheckbox: true,
            },
            ...sourceCells,
            ...targetCells,
          ],
          sx: {
            color: !isItemSelected ? `${theme.palette.monochrome.monoChrome14}!important` : 'inherit',
          },
        };
      }),
    [t, bulkImportRows, excludedImports, activePage, pageSize],
  );

  return { headers, rows, isLoading, totalItems, excludedLines };
}

export default useBulkImportSelectRows;
