import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSnackBar from '../../../../hooks/useSnackBar/useSnackBar';
import useHttpError from '../../../../hooks/useHttpError/useHttpError';
import { RootState } from '../../../../stores/Store';
import MarketsService from '../../../../services/Markets/MarketsService';
import { AvailableNotificationsResponse, Tariff } from '../../../../models/MarketsModels';
import ImportService from '../../../../services/Import/ImportService';
import { UploadType } from '../../../../models/ImportModels';
import { updateImportRequest } from '../../../../stores/ImportStore';

function useBulkImportDrawer(onClose: () => void) {
  const [t] = useTranslation();
  const snackBar = useSnackBar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getHttpReason } = useHttpError();
  const [isLoading, setIsLoading] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState(t('pages.bulkImport.summaryDrawer.title'));
  const [availableNotifications, setAvailableNotifications] = useState<AvailableNotificationsResponse>();
  const [availableTariffs, setAvailableTariffs] = useState<Tariff[]>([]);
  const { bulkImportValidRowsResponse, request, importResponse, excludedImports } = useSelector(
    (state: RootState) => state.importReducer,
  );

  /**
   * Perform the confirm of bulk imports
   */
  const confirmBulkImport = () => {
    if (importResponse) {
      setIsLoading(true);
      ImportService.api
        .confirmBulkImportUpload({
          uploadType: importResponse.uploadType as UploadType,
          uploadId: importResponse.uploadId,
          ...request,
          excludedLines: excludedImports,
        })
        .then(
          () => {
            snackBar.showSnackBar(t('pages.import.uploadSuccess'), 'success');
            navigate('/esimmanager');
            onClose();
          },
          () => {
            snackBar.showSnackBar(t('pages.bulkImport.summaryDrawer.confirmBulkImportError'), 'error');
          },
        )
        .finally(() => setIsLoading(false));
    }
  };

  /**
   * Returns the string to use on journey param for fetchMarketsNotifications
   */
  const getJourneyType = () => {
    switch (importResponse?.uploadType) {
      case UploadType.SIM_SWAPS:
        return 'UPGRADE';
      case UploadType.NEW_CONNECTIONS:
        return 'NEW_CONNECTION';
      default:
        return 'UPGRADE';
    }
  };

  /**
   * Requests the available notifications types for the market of the ban
   * @param ban
   */
  const getAvailableNotifications = async (ban: string) =>
    new Promise((resolve, reject) => {
      const journeyType = getJourneyType();
      MarketsService.api.fetchMarketsNotifications(ban, journeyType).then(
        (res) => {
          setAvailableNotifications(res.data);
          if (
            (res.data.enablePull && !request.notificationType) ||
            (res.data.enablePush && !res.data.enablePull) ||
            (!res.data.enablePush && res.data.enablePull)
          ) {
            dispatch(updateImportRequest({ notificationType: res.data.enablePush ? 'PUSH' : 'PULL' }));
          }
          resolve(res.data);
        },
        (res) => {
          const error = getHttpReason(
            res.response.data,
            t('pages.bulkImport.summaryDrawer.notificationsAvailableDefaultError'),
            t('pages.bulkImport.summaryDrawer.notificationsAvailableError'),
          );
          snackBar.showSnackBar(`${error}.`, 'error');
          reject(res);
        },
      );
    });

  /**
   * Fetches the list of available tariffs for the user's ban
   * @param ban
   */
  const getAvailableTariffs = async (ban: string) =>
    new Promise((resolve, reject) => {
      MarketsService.api.fetchTariffs(ban).then(
        (res) => {
          setAvailableTariffs(res.data.tariffs);
          resolve(res.data.tariffs);
        },
        (res) => {
          const error = getHttpReason(
            res.response.data,
            t('pages.bulkImport.summaryDrawer.fetchTariffsDefaultError'),
            t('pages.bulkImport.summaryDrawer.fetchTariffsError'),
          );
          snackBar.showSnackBar(`${error}.`, 'error');
          reject(res);
        },
      );
    });

  /**
   * On BAN available handler
   * @param ban
   */
  const onBanAvailable = async (ban: string, uploadType: UploadType) => {
    await getAvailableNotifications(ban);
    if (uploadType === UploadType.NEW_CONNECTIONS) {
      await getAvailableTariffs(ban);
    }
  };

  useEffect(() => {
    if (importResponse && bulkImportValidRowsResponse?.ban) {
      onBanAvailable(bulkImportValidRowsResponse.ban, importResponse.uploadType).then();
    }
  }, [bulkImportValidRowsResponse?.ban]);

  useEffect(() => {
    switch (importResponse?.uploadType) {
      case UploadType.SIM_SWAPS:
        setDrawerTitle(t('pages.bulkImport.summaryDrawer.swapSim'));
        break;
      case UploadType.NEW_CONNECTIONS:
        setDrawerTitle(t('pages.bulkImport.summaryDrawer.newConnections'));
        break;
      default:
        setDrawerTitle(t('pages.bulkImport.summaryDrawer.title'));
        break;
    }
  }, [importResponse?.uploadType]);

  return { availableNotifications, isLoading, confirmBulkImport, drawerTitle, availableTariffs };
}

export default useBulkImportDrawer;
